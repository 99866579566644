let tg = window.Telegram.WebApp; //получаем объект webapp телеграма 
tg.expand(); //расширяем на все окно  

let ghc = tg.initData

export async function GetTest() {

    let request_body = {
        "method": "GetTest",
        "params":
        {
        },
        "qhc": ghc
    }

    try {
        const response = await fetch(process.env.REACT_APP_API_ENDPOINT, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(request_body)
        });

        return {
            "status": response.status,
            "data": await response.json()
        }

    } catch (error) {
        console.error('Error getting data:', error);
        throw error;
    }
}