import logo from './logo.svg';
import './App.css';

import { useEffect, useState } from 'react';
import { GetTest } from './Api';

function App() {

  const [dataResponse, setDataResponse] = useState(
    {
      status: '',
      data: {}
    }
  )

  const [callGetTest, setCallGetTest] = useState(0)


  useEffect(() => {

    GetTest()
      .then(response => {
        const status = response['status']
        const data = response['data']

        console.log('status:' + status)

        setDataResponse({
          status: status,
          data: data
        })


      })
      .catch(error => {
        console.error('Error getting account:', error);
        return;
      });

  }, [callGetTest]);



  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>




      <button className='button' onClick={() => setCallGetTest(callGetTest + 1)}> Click me </button>
      <br />
      <section className='response_area'>
        <p><b>Response here:</b></p>

        <p>{JSON.stringify(dataResponse)} </p>
      </section>


    </div>
  );
}

export default App;
